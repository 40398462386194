import * as React from 'react'
import { LayoutBootstrap } from '../components/layoutBootstrap'
import { Container, Row, Col } from 'react-bootstrap'
import "../styles/styles.css"
import { StaticImage } from 'gatsby-plugin-image'
import { LanguageContext } from '../components/language'
import { T } from '../translation/translate'

const StudentPage = () => {
    const {lang, setLang} = React.useContext(LanguageContext);

return(
    <LayoutBootstrap>
        <Container fluid style={{backgroundColor:'#6ba7cf'}}>
            <Row className="no-gutters">
                <Col style={{padding:0}}>
                    <StaticImage src={"../images/pages/students.png"} className="image" alt="study"/>
                </Col>
            </Row>
        </Container>

        <Container fluid className="oddcontainer">

               
                    <Row className='oddcontainer'><Col>
                    <h2>{T(lang, {en:"Studying Science Education and Communication(SEC)",nl:"Science Education and Communication (SEC) studeren"})}</h2>
                    {T(lang,{en:"The master Science Education and Communication (SEC) is a two-year master program at Delft University of Technology. The master SEC consists of two tracks: the Science Education track and the Communication Design for Innovation track.",
                             nl:"De master Science Education and Communication (SEC) is een tweejarig master programma aan de TU Delft. De master bestaat uit twee onafhankelijke tracks: de Science Education (SE) track en de Communication Design for Innovation (CDI) track. "
                    })}
                </Col>
            </Row>
        </Container>

        <Container fluid className="evencontainer">
            <Row className='evencontainer'><Col>
                    <h2>Science Education</h2>
                    {T(lang, {en:"The Education track educates the students to become a teacher in one of four subjects in upper secondary education: Physics, Chemistry, Mathematics, Onderzoeken en Ontwerpen (Technasium) or Computer Science. Students learn to become teachers in upper secondary education, with a thorough knowledge of the field as well as didactic competencies. After their studies they will have certificates to be able to teach in HAVO and VWO classes.",
                              nl:"Deze educatie track leidt studenten op tot leraar natuurkunde, scheikunde, wiskunde, onderzoeken en ontwerpen (O&O, een technasium vak) of informatica op de middelbare school. Studenten leren om docent te worden in de bovenbouw, door niet alleen een grondige kennis te krijgen van het desbetreffende vakgebied, maar ook door goede didactische vaardigheden te ontwikkelen. Na deze master ontvangt de student een certificaat waarmee zowel in HAVO als in VWO klassen lesgegeven mag worden."
                })}

                </Col>
            </Row>
        </Container>

        <Container fluid className="oddcontainer">
            <Row>
                <Col>

                    <Row className='oddcontainer'>
                        <Col>
                        <h2>Communication Design for Innovation (CDI)</h2>
                            {T(lang, {en:"The CDI track provides the students with a first step to become science communication specialists. By offering a refreshing view of communication in innovation processes, students develop the skills needed to facilitate dialogue and effective knowledge transfer between scientists and key players in industry, government and society. CDI can be combined with other MSc-programmes into a double degree program.",
                              nl:"De CDI track leidt studenten op om wetenschapscommunicatie specialisten te worden. Door ze een verversende blik op communicatie binnen innvoatieprocessen te bieden, ontwikkelen studenten de vaardigheden die nodig zijn om de dialoog tussen te faciliteren tussen verschillende partijen die bij dergelijke processen betrokken zijn. Ook leren ze om bij te dragen aan een zo effectief mogelijke kennisuitwisseling tussen wetenschappers, belangrijke spelers in de industrie, overheid en maatschappij. CDI kan goed gecombineerd worden met andere masterprogramma’s tot een double degree programma."
                            })}                    
                        </Col>'
                    </Row>
                </Col>
            </Row>
        </Container>

        <Container fluid className='evencontainer'>
                <Row className='evencontainer'><Col>
                    <h2>{T(lang, {en:"Information for new students", nl:"Informatie voor nieuwe studenten"})} </h2>
                        <p><span style={{fontWeight:'bold'}}>{T(lang, {en:"Buying books: ",nl:"Boeken kopen: "})}</span> 
                                {T(lang,{en:"InterSECtion has a deal with the online book shop StudyStore for buying the books that are needed for the SEC programme. In this way you can easily buy all your necessary books at one place, and you also get a nice discount! So not only very convenient, but also economically attractive. To make use of this, please visit their website via this ",
                                     nl:"InterSECtion heeft een deal met de online boekenwinkel StudyStore voor de aanschaf van de boeken die nodig zijn voor het SEC-curriculum. Zo kan je in één keer alle boeken bestellen die je nodig hebt en krijg je ook nog eens een leuke korting! Dus zowel makkelijk als financieel aantrekkelijk. Om hier gebruik van te maken, kan via deze "})}
                                <a className='whitelink' to={"https://intersection.itdepartment.nl/"}>link</a>.
                        </p>
                        <p><span style={{fontWeight:'bold'}}>{T(lang, {en:"More information about doing SEC as a double degree: ",nl:"Meer informatie over het volgen van SEC als dubbele master: "})}</span> 
                        {T(lang,{en:"send an email to ",
                                 nl:"stuur een mailtje naar "})}
                            <a className='whitelink' to={"mailto:info-sec@tudelft.nl"}>info-sec@tudelft.nl</a>.
                        </p>
                        <p><span style={{fontWeight:'bold'}}>{T(lang, {en:"More information about the content of the SEC program or career possibilities: ",nl:"Meer informatie over de inhoud van het SEC curriculum of carrièremogelijkheden: "})}</span> 
                            {T(lang,{en:"visit the ",
                                 nl:"bezoek de "})}
                            <a className='whitelink' to={"http://sec.msc.tudelft.nl/"}>SEC Website</a>
                            {T(lang,{en:" of the TU Delft or send us an e-mail at ",
                                 nl:" van de TU Delft of stuur ons een mailtje op "})}
                            <a className="whitelink" to={"mailto:intersection@tudelft.nl"}>intersection@tudelft.nl</a>.                            
                        </p>
                        <p><span style={{fontWeight:'bold'}}>{T(lang, {en:"More information about InterSECtion activities or other questions: ",nl:"Meer informatie over InterSECtion activiteiten of overige vragen: "})}</span> 
                        {T(lang,{en:"send us an e-mail at ",
                                 nl:"voel je vrij om ons over wat dan ook een mailtje te sturen op "})}
                            <a className='whitelink' to={"mailto:intersection@tudelft.nl"}>intersection@tudelft.nl</a>.
                        {T(lang,{en:" You are also welcome to drop by the InterSECtion office (Building 22, room A014) or contact one of the board members personally. ",
                                 nl:" Je mag ook altijd langskomen bij ons kantoor (Gebouw 22, kamer A014) of een van ons persoonlijk benaderen. "})}
                        </p>
                    </Col></Row>
        </Container>


    </LayoutBootstrap>
)

}

export default StudentPage